import useCookieListener from 'data/hooks/useCookieListener';
import { ClarityUtils } from 'utils/clarityUtils';

const useClarityCookieConsent = () => {
    useCookieListener((cookieValue) => {
        const [cookieKey, cookieData] = cookieValue.split("=");

        if (cookieKey === 'cookieyes-consent') {
            const parts = decodeURIComponent(cookieData).split(",");

            parts.forEach(part => {
                const [key, value] = part.split(":");
                if (key === 'analytics' && value === 'yes') {
                    ClarityUtils.cookieConsent(true);
                }
            });
        }
    });
};

export default useClarityCookieConsent;
