import { useEffect } from 'react';

const useCookieListener = (callback: (cookieValue: string) => void) => {
    useEffect(() => {
        const originalCookieSetter = Object.getOwnPropertyDescriptor(Document.prototype, 'cookie')!.set;
        const originalCookieGetter = Object.getOwnPropertyDescriptor(Document.prototype, 'cookie')!.get;

        const handleCookieChange = (cookieValue: string) => {
            callback(cookieValue);
        };

        Object.defineProperty(document, 'cookie', {
            configurable: true,
            enumerable: true,
            get() {
                return originalCookieGetter!.call(document);
            },
            set(cookieValue: string) {
                handleCookieChange(cookieValue);
                originalCookieSetter!.call(document, cookieValue);
            }
        });

        return () => {
            Object.defineProperty(document, 'cookie', {
                configurable: true,
                enumerable: true,
                get: originalCookieGetter,
                set: originalCookieSetter
            });
        };
    }, [callback]);
};

export default useCookieListener;
